import { useEffect, useState, useRef} from "react";
import FullScreenIcon from "../../assets/Icons/fullscreen.png";
import FilterIcon from "../../assets/Icons/filter.png";
import styles from "../../styles/Photobooth.module.css";
import CreatePhoto from "./Create_photo";
import EnterEmail from "./Email";
import QrCode from "./QR_Code";
import CreateGif from "./Create_gif";
import ColorSettings from "../Settings/Color";
import ErrorModal from "../../assets/Error_Modal";
import BackgroundImgDefault from "../../assets/Images/Background.png";
import { app } from "../../../config/firebase";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default function PhotoBoothPage() {

    const db = getFirestore(app);

    const storage = getStorage();

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [view, setView] = useState("");
    const [ colorSettings, setColorSettings] = useState(false);
    const [filter, setFilter] = useState(getFilter());
    const [videoFilter, setVideoFilter] = useState({});
    const [errMsg, setErrMsg] = useState("");

    const screenEl = useRef(null);
    const backgroundImg = useRef(null);
    
    useEffect(() => {
        async function getBackgroundImg() {
            const frameName = localStorage.getItem("settings").split(" ")[2];
            await getDocs(collection(db, "Background")).then((snapShot) => {
                snapShot.forEach((doc) => {
                    if(doc.data().frameName === frameName) {
                        getDownloadURL(ref(storage, doc.data().imgName)).then((url) => {
                            backgroundImg.current=url;
                        }).catch (err => setErrMsg(err));
                    }
                })
            }).catch (err => setErrMsg(err))
        }

        getBackgroundImg();

    }, [db, storage])

    useEffect(() => {
        setVideoFilter({filter:filter})
    }, [filter])

    useEffect(() => {

        screenEl.current.addEventListener("fullscreenchange", () => {
            if(!document.fullscreenElement){
                setIsFullScreen(false);
                screenEl.current.style.background = "none";
            }else{
                if(backgroundImg.current) {
                    screenEl.current.style.backgroundImage = `url(${backgroundImg.current})`;
                    screenEl.current.style.backgroundSize = "cover";
                    screenEl.current.style.backgroundRepeat = "no repeat";
                }else {
                    screenEl.current.style.backgroundImage = `url(${BackgroundImgDefault})`;
                    screenEl.current.style.backgroundSize = "cover";
                    screenEl.current.style.backgroundRepeat = "no repeat";
                }
            }
        })
    }, [screenEl, backgroundImg])

    function fullScreenMode(e) {
        e.preventDefault();
        const fullScreenDiv = document.getElementById("fullscreen");
        setIsFullScreen(true)
        if (fullScreenDiv.requestFullscreen) {
            fullScreenDiv.requestFullscreen();
          } else if (fullScreenDiv.mozRequestFullScreen) {
            fullScreenDiv.mozRequestFullScreen();
          } else if (fullScreenDiv.webkitRequestFullscreen) {
            fullScreenDiv.webkitRequestFullscreen();
          } else if (fullScreenDiv.msRequestFullscreen) {
            fullScreenDiv.msRequestFullscreen();
          }
    }

    function settingsButton(e) {
        e.preventDefault();

        if(colorSettings){
            setColorSettings(false);
        }else{
            setColorSettings(true);
        }
    }

    function selectBtn(e) {
        e.preventDefault();
        setView(e.target.value);
    }

    return (
        <div id="target" className={styles.container}>
            {errMsg &&(<ErrorModal errMsg={errMsg} setErrMsg={setErrMsg} />)}
            <button className={styles.fullscreen_btn} type="button" onClick={e => {fullScreenMode(e)}} ><img className={styles.fullscreen_btn_icon} src={FullScreenIcon} alt="Fullscreen icon"/></button>
            <button className={styles.settings_btn} type="button" onClick={e => {settingsButton(e)}}><img className={styles.settings_btn_icon} src={FilterIcon} alt="settings icon" /></button>
            <div className={`${styles.fullscreen_container}`} id="fullscreen" ref={screenEl} >
                {!view && (<div className={styles.button_container}>
                    <button className={styles.button} value="photo" type="button" onClick={e => {selectBtn(e)}}>Photo</button>
                    <button className={styles.button} value="gif" type="button" onClick={e => {selectBtn(e)}}>Gif</button>
                </div>)}
                {view === "photo" && (<CreatePhoto videoFilter={videoFilter} setView={setView} isFullScreen={isFullScreen} />)}
                {view === "email" && (<EnterEmail setView={setView} />)}
                {view === "qrcode" && (<QrCode setView={setView}  />)}
                {view === "gif" && (<CreateGif videoFilter={videoFilter} setView={setView} isFullScreen={isFullScreen}   />)}
            </div>
            {colorSettings && (<ColorSettings setFilter={setFilter} />)}
        </div>
    )
}

function getFilter() {
    const filter = sessionStorage.getItem("filter");

    if(filter) {
        return filter;
    }else{
        return ("none")
    }
}
