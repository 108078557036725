import { useParams } from "react-router-dom";
import { useState} from "react";
import LoaderPrimary from "../../assets/Loader";
import DownloadIcon from "../../assets/Icons/download.png";
import { getStorage, ref, getDownloadURL} from "@firebase/storage";
import { usePhotoStore } from "../../function/Photos";
import { app } from "../../../config/firebase";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import styles from "../../styles/Download.module.css";

export default function DownloadPage() {
    const params = useParams();

    const db = getFirestore(app);

    const storage = getStorage();

    const { photos, addPhotos} = usePhotoStore();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");

    function viewPhotos(e) {
        e.preventDefault();
        setLoader(true);
        
        // Get all documents from the collection
        getDocs(collection(db, params.event))
            .then((snapShot) => {
            if (snapShot.empty) {
                alert("No Photos Found");
                setLoader(false);
            } else {
                // Filter documents based on eventName and picId
                const matchingDocs = snapShot.docs.filter(doc => {
                return doc.data().event === params.event && doc.data().picId === params.picId;
                });
        
                if (matchingDocs.length === 0) {
                alert("No photos found.");
                setLoader(false);
                return; // Exit the function if no matches
                }
        
                // Process matching documents
                matchingDocs.forEach((doc) => {
                getDownloadURL(ref(storage, doc.id))
                    .then((url) => {
                    addPhotos(url);
                    })
                    .catch((error) => {
                    // Handle storage errors as before
                    switch (error.code) { 
                        case 'storage/object-not-found':
                          setError( "File doesn't exist")
                          break;
                        case 'storage/unauthorized':
                            setError("User doesn't have permission to access the object")
                          break;
                        case 'storage/canceled':
                            setError("User canceled the upload")
                          break;
                        case 'storage/unknown':
                            setError("Unknown error occurred, inspect the server response")
                          break;
                        default :
                          break;
                        }
                    })
                    .finally(() => {
                    setLoader(false);
                    });
                });
            }
            });
    }

    function DownloadPhotos(e, url) {
        e.preventDefault();
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            const blob = xhr.response;
            const blobUrl = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            const newUrl = new URL(url);
            const pathname = newUrl.pathname.split("/")[5];
            link.href = blobUrl;
            link.setAttribute("download", `${pathname}.${params.type}`);
            document.body.appendChild(link);
            link.click();
                document.body.removeChild(link);

        };
        xhr.open('GET', url);
        xhr.send();
    }

   if(loader) {
     return (
        <div className={styles.download_container}>
            <LoaderPrimary />
        </div>
     )
   }

   if(error){
    return (<div>
                <h3>{error}</h3>
            </div>)
   }

    return (
        <>
            <div className={styles.download_container}>
                {photos.length === 0 ? 
                    (<div className={styles.view_btn_cont}>
                        <h3 className={styles.text}>Thank you for using ZPhotoBooth.</h3>
                        <button className={styles.view_btn} onClick={e => {viewPhotos(e)}}>View Your Photos</button>
                        <p className={styles.text}>Help Us Improve. Give <a href="https://docs.google.com/forms/d/e/1FAIpQLScRgSxdw-38ldNaUNMbe45d6XH4VzcZVzTUvON5fLZFw1zicg/viewform" target="_blank" rel="noreferrer">feedback.</a></p>
                    </div>) : 
                    (<div className={styles.photo_div}>
                        <h3 className={styles.text}>Help Us Improve. Give <a href="https://docs.google.com/forms/d/e/1FAIpQLScRgSxdw-38ldNaUNMbe45d6XH4VzcZVzTUvON5fLZFw1zicg/viewform" target="_blank" rel="noreferrer">feedback.</a></h3>
                        <p><strong>Download photos/gif by clicking the download button or press and hold the photo/gif</strong></p>
                        <div className={styles.photos_cont}>
                            {photos.map((photo, i) => (
                            <li className={styles.li} key={i}>
                                <button type="button" className={styles.download_button} onClick={e => {DownloadPhotos(e, photo)}}><img src={DownloadIcon} alt="download" className={styles.download_icon} /></button>
                                <img src={photo} alt="photobooth result" className={styles.photo} />
                            </li>))}
                        </div>
                    </div>)
                }
            </div>
        </>
    )
}
