import { usePhotoStore } from "../../../function/Photos";
import styles from "../../../styles/Preview.module.css";

export default function GifPreview(props) {

    const { photos, clearPhotos } = usePhotoStore();

    function photoAgain(e) {
        e.preventDefault();
        clearPhotos();
        props.setGifUrl(null);
    }

    async function photoFinish(e){
        e.preventDefault();
        localStorage.setItem("fileType", "gif")
        props.setView("qrcode");
    }

    return (
        <>
            <div className={`${styles.preview_container} ${props.orientation === "portrait"? styles.portrait : ""}`}>
                {photos[0] && <img className={styles.photo} src={photos[0]} alt="Photobooth result." />}
            </div>
            <div className={styles.button_container}>
                        <button className={styles.button} onClick={(e) => {photoAgain(e)}}>Retry</button>
                        <button className={styles.button} onClick={(e) => {photoFinish(e)}}>Finish</button>
            </div>
        </>
    )

}