import { useState, useEffect, useMemo } from "react";
import styles from "../../../styles/Counter.module.css";

export default function Counter(props) {

    const [timer, setTimer] = useState("");
    const [index, setIndex] = useState(0);
    const [timerEnd, setTimerEnd] = useState(false);

    const timerArray = useMemo(() => {
        if(props.view === "gif"){
            return ["Get Ready", "3", "2", "1", "Move"];
        }else {
           return ["Get Ready", "3", "2", "1", "Say Cheese!"];
        }
    }, [props.view]);

    useEffect(() => {

        function getTimer() {
            setTimer(timerArray[index])
            setIndex(index + 1)
        }

        if(index < timerArray.length){
            const interval = setInterval(() => getTimer(), 1000);
        
            return () => clearInterval(interval);
        }else{
            setTimeout(() => {
                setTimeout(() => {
                    setTimerEnd(true);
                    props.setCountdownFinish(true);
                }, 1000);
            }, 1000);
        }
    }, [index, timerArray, props.setCountdownFinish, props.setTakeGif, props])
    
    return (
        <>
            {timerEnd? " " : 
            (<div className={styles.counter_container}>
                {<h4 className={`${styles.h4}  ${index < timerArray.length? (styles.timer) : (styles.cheese)}`}>{timer}</h4>}
            </div>)}
        </>
    )
}