import {useState} from "react";
import LoaderPrimary from "../../../assets/Loader";
import { usePhotoStore } from "../../../function/Photos";
import PostData from "../../../function/Send-Photos";
import styles from "../../../styles/Create_Qr.module.css";
import KeyBoard from "../../../function/Keyboard";
import QRCode from "qrcode.react";

export default function QrCode(props) {

    const eventName = localStorage.getItem("settings").split(" ")[4];

    const fileType = localStorage.getItem("fileType")

    const { photos, clearPhotos } = usePhotoStore();

    const [loader, setLoader] = useState(false);
    const [name, setName] = useState("");
    const [error, setError] = useState("");
    const [qrCodeValue, setqrCodeValue] = useState("");
    const [permission, setPermission] = useState(false);
    const [savingFail, setSavingFail] = useState(false);

    async function createQr(e) {
        e.preventDefault();
        const date = new Date().getTime();
      
        if (name) {
          setLoader(true);
          const photoName = name.split(" ")[0];
      
          try {
            for (let i = 0; i < photos.length; i++) {
              const photo = photos[i];
              if(navigator.onLine) {
                const success = await PostData(photo, photoName + date, permission, eventName);

                if (!success) {
                  setLoader(false);
                  setSavingFail(true);
                }else {
                  clearPhotos();
                  const url = window.location.origin;
                  setqrCodeValue(url + "/download/" + eventName + "/" + photoName + date + "/" + fileType);
                  setLoader(false);
                }
              }else {
                setLoader(false);
                setSavingFail(true);
              }

            }
    
          } catch (err) {
            setLoader(false);
            setSavingFail(true);
          }
        } else {
          setError("Name must not be empty.");
        }
      }
      
    function doneQrScanning(e) {
        e.preventDefault();
        props.setView("");
    }

    function selectAction(e) {
        e.preventDefault();
        if(e.target.value === "Yes") {
          props.setView("email")
        }else {
          setSavingFail(false);
        }
    }

    if(loader){
        return <LoaderPrimary />
    }

    if(savingFail) {
      return (
        <div className={styles.container}>
            <div className={styles.form}>
                <h3>Sorry, we have an error saving your photo. Do want us to save your photo and send it to your email later or do you want to try to send it again?</h3>
                <button className={styles.enter_btn} value="Yes" onClick={e => {selectAction(e)}} type="button"
                  >Send to Email</button>
                  <button className={styles.enter_btn} value="No" onClick={e => {selectAction(e)}} type="button"
                  >Try to send again</button>
            </div>
        </div>
      )
    }


    return (
        <div className={styles.container}>
            {qrCodeValue ? 
            <div className={styles.form}>
                <h2>Scan QR Code to Download Your Photos</h2>
                <QRCode value={qrCodeValue} size={250} />
                <button className={styles.enter_btn} onClick={e => {doneQrScanning(e)}}>Done</button>
            </div> :
            <>
                <form className={styles.form}>
                    <div className={styles.input_container}>
                        <label className={styles.label} htmlFor="user_input">Enter Name</label>
                        <input className={styles.input} name="name" type="text" id="user-input" onChange={e => {setName(e.target.value)}} autoComplete="off"/>
                        {error && (<p className={styles.error_text}>{error}</p>)}
                        <div>
                          <h3>WE VALUE YOUR PRIVACY.</h3>
                          <div>
                            <input type="checkbox" name="permission"  id="user-permission" onChange={e => {setPermission(e.target.checked)}}/>
                            <label htmlFor="user-permission">DO NOT POST ON ANY SOCIAL MEDIA.</label>
                            {permission && (<p className={styles.error_text}>Guaranteed, we will not post it on any social media.</p>)}
                          </div>
                        </div>
                    </div>
                    <button className={styles.enter_btn} onClick={e => {createQr(e)}}>Send</button>
                </form>
                <KeyBoard input={setName} />
            </> }
        </div>
    )
}