import { getAuth, onAuthStateChanged } from "firebase/auth";

export default async function GetAuth() {
    try {
        const auth = getAuth();

        let userId = null;
        await new Promise((resolve) => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                userId = user.uid;
                } else {
                alert("User is not logged in. Please log in to upload.");
                resolve(false); 
                }
                resolve(true);
            });
        });

        if (!userId) {
            window.location.href= "/";
        }else{
            return userId;
        }

    } catch (error) {
        alert(error);
        window.location.href= "/";
    }
}