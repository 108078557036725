import { useEffect, useState } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

export default function OnScreenKeyBoard(props) {
    const [input, setInput] = useState();

    function keyChange(key) {
        props.input(key)
        input.value = key;
    }

    useEffect(() => {
        const emailInput = document.getElementById("user-input");
        setInput(emailInput);
    }, []);

    if(props.form === "email"){
        return (
            <Keyboard
                onChange={e => {keyChange(e)}}
                layout={{default: [
                    '1 2 3 4 5 6 7 8 9 0 @ {bksp}',
                    'q w e r t y u i o p å ø æ',
                    'a s d f g h j k l , .',
                    'z x c v b n m _ - .com .no',
                  ],}}
            />
        )
    }else {
        return (
            <Keyboard
                onChange={e => {keyChange(e)}}
                layout={{default: [
                    '1 2 3 4 5 6 7 8 9 0 @ {bksp}',
                    'q w e r t y u i o p å ø æ',
                    'a s d f g h j k l , .',
                    'z x c v b n m .com',
                  ],}}
            />
        )
    }

}
