import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import GetAuth from "../../function/Auth";
import { collection, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore";
import { app } from "../../../config/firebase";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { usePhotoStore } from "../../function/Photos";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import './styles.css';
import styles from "../../styles/Slideshow.module.css";
import FullScreenIcon from "../../assets/Icons/fullscreen.png";
import LoaderPrimary from "../../assets/Loader";

export default function Slideshow() {

    const auth = GetAuth();

    if(!auth){
        window.location.href = "/";
    }

    const db = getFirestore(app);
    const storage = getStorage();

    const { photos, addPhotos} = usePhotoStore();

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [updating, setUpdating] = useState(true);

    const params = useParams();

    const screenEl = useRef(null);

    useEffect(() => {
        const idArray = [];
        let count = 0;
        const photosCollectionRef = collection(db, "Slideshow", "eventSlideShow", params.event);
        const q = query(photosCollectionRef, orderBy("timestamp", "desc"));
        onSnapshot(q, (snapshot) => {
            setUpdating(true);
            const photosId = snapshot.docs.map((doc) => ({
                id: doc.data().picName
            }));

            if(count !== 0 ) {
                const idValues = idArray.map(obj => obj.id);

                const newIdArray = photosId.filter( newId => !idValues.includes(newId.id));

                newIdArray.forEach( doc => {
                    getDownloadURL(ref(storage, doc.id)).then((url) => {
                        addPhotos(url);
                    })
                    count = count + 1;
                    idArray.push(doc);
                });

            }else{
                photosId.forEach( doc => {
                    getDownloadURL(ref(storage, doc.id)).then((url) => {
                        addPhotos(url);
                    })
                    count = count + 1;
                    idArray.push(doc);
                });
            }

            setTimeout(() => {
                setUpdating(false);
            }, 5000);
        });
    }, [addPhotos, db, params.event, storage])

    useEffect(() => {

        screenEl.current.addEventListener("fullscreenchange", () => {
            if(isFullScreen){
                setIsFullScreen(false);
            }
        })
    }, [screenEl, isFullScreen])

    function fullScreenMode(e) {
        e.preventDefault();
        const fullScreenDiv = document.getElementById("fullscreen");
        setIsFullScreen(true)
        if (fullScreenDiv.requestFullscreen) {
            fullScreenDiv.requestFullscreen();
          } else if (fullScreenDiv.mozRequestFullScreen) {
            fullScreenDiv.mozRequestFullScreen();
          } else if (fullScreenDiv.webkitRequestFullscreen) {
            fullScreenDiv.webkitRequestFullscreen();
          } else if (fullScreenDiv.msRequestFullscreen) {
            fullScreenDiv.msRequestFullscreen();
          }
    }

    return (
        <>
            <button className={styles.fullscreen_btn} type="button" onClick={e => {fullScreenMode(e)}} ><img className={styles.fullscreen_btn_icon} src={FullScreenIcon} alt="Fullscreen icon"/></button>
            <div className={`${styles.fullscreen_container}`} id="fullscreen" ref={screenEl}>
                {updating? <div className={styles.loader_div}><LoaderPrimary /></div>: 
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        loop={true}
                        effect="fade"
                        modules={[Autoplay, Pagination, Navigation, EffectFade]}
                        className="mySwiper"
                    >
                        {photos.map((photo, i) => (
                                <SwiperSlide key={i}><img src={photo} alt="slideshow" /></SwiperSlide>
                            ))}
                    </Swiper>}
            </div>
        </>
    )
}