import React, { useState, useRef, useEffect } from 'react';
import GIF from "gif.js";
import styles from "../../../styles/Gif.module.css";
import Counter from '../Counter';
import { useFrameStore } from '../../../function/Frame';
import StartCamera from '../../../function/Camera';
import { Orientation } from '../../../function/Orientation';
import Resolution from '../../../function/Resolution';
import GifPreview from '../Gif_preview';
import { usePhotoStore } from '../../../function/Photos';
import LoaderPrimary from '../../../assets/Loader';
import "context-filter-polyfill";

export default function CreateGif( props ) {

  const orientation = Orientation();

  const res = Resolution();

  const { addPhotos } = usePhotoStore();

  const { frames } = useFrameStore();

  const [gifUrl, setGifUrl] = useState(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [takeGif, setTakeGif] = useState(false);
  const [countdownFinish, setCountdownFinish] = useState(false);
  const [rendering, setRendering] = useState(false);

  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const frame = useRef();

  useEffect(() => {
    if(videoPlaying){
      document.getElementById("video").style.filter = (props.videoFilter.filter);
    }
    
  }, [props.videoFilter, videoPlaying])

  useEffect(() => {
    frame.current = new Image();
    frame.current.crossOrigin = "anonymous";
    frame.current.src = frames[0];
    
    StartCamera(videoRef.current, streamRef.current, videoPlaying, setVideoPlaying,);

  }, [videoPlaying, frames])

  useEffect(() => {
    if(countdownFinish && !gifUrl){
      const gif = new GIF({
        workers: 2,
        quality: 10,
        workerScript: '/gif.worker.js',
      });

      const video = videoRef.current;


      // Capture frames at a regular interval
      const captureFrame = () => {
          const canvas = document.createElement("canvas");

          // Add willReadFrequently: true for better performance
          const ctx = canvas.getContext('2d', { willReadFrequently: true });

          canvas.width = res.width;
          canvas.height = res.height;
      
          // Draw the current video frame onto the canvas
          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = "high";
          ctx.filter = (props.videoFilter.filter);
          ctx.drawImage(video, 0, 0, res.width, res.height);
          ctx.filter =`none`;
          ctx.drawImage(frame.current, 0, 0, res.width, res.height);
        
          // Add the canvas frame to the GIF
          gif.addFrame(canvas, { delay: 100 });
        };
      let captureInterval = setInterval(captureFrame, 100); // Capture every 100ms (adjust as needed)

      // Stop capturing and render GIF after 3 seconds (or adjust the duration)
      setTimeout(() => {
        clearInterval(captureInterval);
        gif.render();
        setRendering(true);
        setTakeGif(false);
        setCountdownFinish(false);
      }, 3000);

      gif.on('finished', (blob) => {
        function blobToDataURL(blob, callback) {
          const reader = new FileReader();
      
          // When FileReader finishes reading the blob
          reader.onloadend = function() {
              callback(reader.result);  // This will be the Data URL (base64 encoded)
          };
      
          // Start reading the blob as a Data URL
          reader.readAsDataURL(blob);
        }
          blobToDataURL(blob, (dataURL) => {
            addPhotos(dataURL);
            setGifUrl(dataURL);
            setRendering(false);
        });

      });
    }
  }, [countdownFinish, props.videoFilter, res.height, res.width, addPhotos, gifUrl]);

  return (
    <>
      <div className={`${gifUrl? ("") : (styles.display_hide)} ${styles.take_photo_container} `}>
        <GifPreview orientation={orientation} setView={props.setView}  setGifUrl={setGifUrl} view={"gif"} />
      </div>

      <div className={`${styles.take_photo_container} ${gifUrl? (styles.display_hide) : ("")}`} >
          <div className={`${styles.camera} ${rendering? (styles.display_hide) : ("")} `} id="camera">
              <video className={`${props.isFullScreen? (styles.fullscreen_video) : (styles.video)}`} id="video" preload="none" ref={videoRef}></video>
              <img className={`${orientation === "landscape" ? styles.frame_landscape : styles.frame_orientation}`} src={frames[0]} alt="frame"/>
              <button className={`${styles.capture_btn} ${takeGif ?(styles.display_hide) : ""}`} id="capture" onClick={()=> {setTakeGif(true)}}>Take Gif</button>
          </div>

          <div className={`${!rendering? (styles.display_hide) : ("")}`}>
                <LoaderPrimary />
          </div>

        {takeGif && (
          <Counter videoFilter={props.videoFilter} setView={props.setView} orientation={orientation} stream={videoRef.current} setCountdownFinish={setCountdownFinish} view={"gif"} />
        )}
      </div>
    </>
  );
};
