import styles from "../../styles/Settings.module.css";
import { useState, useEffect, useRef } from "react";
import { app } from "../../../config/firebase";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { getAuth} from 'firebase/auth';

export default function Frame(props) {

    const db = getFirestore(app);

    const [loader, setLoader] = useState(true);

    const frameCollections = useRef([]);

    useEffect(() => {

        async function getFrameCollections() {
            const auth = getAuth();
            const uid = auth.currentUser.uid;
          
            const db = getFirestore();
          
            const collections = await getDocs(
              query(collection(db, "frame"), where("userId", "==", uid))
            );
            collections.docs.forEach((doc) => {
              frameCollections.current.push(doc.data());
            });

            setLoader(false);
          }
          
          getFrameCollections();
    }, [db]);

    if( loader) {
        return <></>
    }

    return (
        <div className={styles.input_cont}>
            <label htmlFor="frame" className={styles.label}>Frame Collections : </label>
            <select id="frame" value={props.frameValue} onChange={e => {props.setFrameValue(e.target.value)}} className={styles.select}>
                <option value="None">None</option>
                {frameCollections.current.map((list, i) => {
                    return <option key={i} value={list.frameName}>{list.event}</option>
                })}
            </select>
        </div>
    )

}