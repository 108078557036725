import { useState, useEffect } from "react";
import styles from "../../styles/Settings.module.css";
import LoaderPrimary from "../../assets/Loader";
import Resolution from "./resolution";
import AspectRatio from "./aspect";
import Frame from "./frame";
import FrameImg from "../../assets/Images/frame.png";
import Orientation from "./orientation";
import CreateEventName from "./collection_name";
import { useFrameStore } from "../../function/Frame";
import { app } from "../../../config/firebase";
import { getFirestore, collection, getDocs, addDoc, query, where} from "firebase/firestore";
import { getStorage, ref, getDownloadURL} from "firebase/storage";
import GetAuth from "../../function/Auth";

export default function SettingsPage() {
    
    const db = getFirestore(app);

    const storage = getStorage();

    const { addFrame, clearFrames } = useFrameStore();

    const [res, setRes ] = useState("1440x900");
    const [ aspectRatio, setAspectRatio] = useState("10/16");
    const [frameValue, setFrameValue ] = useState("None");
    const [ orientation, setOrientation] = useState("Landscape");
    const [eventName, setEventName] = useState("newEvent");
    const [loader, setLoader] = useState(false);
    const [userId, setUserId] = useState("");

    useEffect(() => {
        const settings = localStorage.getItem("settings");
        if(settings){
            setRes(settings.split(" ")[0]);
            setAspectRatio(settings.split(" ")[1]);
            setFrameValue(settings.split(" ")[2]);
            setOrientation(settings.split(" ")[3]);
            setEventName(settings.split(" ")[4]);
        }

    }, [])

    useEffect(() => {
       async function getUserId() {
        const id = await GetAuth();
        if(id){
            setUserId(id)
        }else{
            window.location.href="/";
        }
       }

       getUserId();
    }, [])

    function saveSettings(e){
        e.preventDefault();
        setLoader(true)
        clearFrames();

        if(frameValue !== "None"){
            async function getFrame() {
                await getDownloadURL(ref(storage, frameValue)).then((url) => {
                    addFrame(url);
                    createCollection();
                }).catch(err => alert(err));
            }

            getFrame();
        }else{
            addFrame(FrameImg);
            createCollection();
        }

        async function createCollection() {
            await addDoc(collection(db, eventName), {
                    event: eventName,
                    userId: userId,
                }).then((docRef) => {
                    addDocumentIfUnique(docRef);
                }).catch( err => alert(err))

        }

        async function addDocumentIfUnique(docRef) {
                // Check if a document with the same name already exists
                const q = query(collection(db, "My Collections"), where("name", "==", eventName));
                await getDocs(q).then((querySnapshot) => {
                    if (querySnapshot.empty) {
                        // No document with the same name exists, so add the new document
                        addDoc(collection(db, "My Collections"), {
                            name: eventName,
                            docId: docRef.id,
                            userId: userId,
                        }).then(() => {
                            localStorage.setItem("settings", res + " " + aspectRatio + " " + frameValue + " " + orientation + " " + eventName + " " + docRef.id);
                            window.location.href="/";
                            setLoader(false);
                        }).catch(err => {
                            alert(err);
                            setLoader(false);
                        });    
                    }else {
                        localStorage.setItem("settings", res + " " + aspectRatio + " " + frameValue + " " + orientation + " " + eventName + " " + docRef.id);
                        window.location.href="/";
                        setLoader(false);
                    }
                }).catch(err => {
                    alert(err);
                    setLoader(false);
                });
        }
    }


    return (
        <div className={styles.container}>
            {
            loader? (<LoaderPrimary />) : (<form className={styles.form}>
                <div className={styles.settings_cont}>
                    <Resolution res={res} setRes={setRes} />
                    <AspectRatio aspectRatio={aspectRatio} setAspectRatio={setAspectRatio} />
                    <Frame frameValue={frameValue} setFrameValue={setFrameValue} />
                    <Orientation  orientation={orientation} setOrientation={setOrientation} />
                    <CreateEventName eventName={eventName} setEventName={setEventName} />
                </div>
                <button onClick={e => {saveSettings(e)}} className={styles.button}>Save</button>
            </form>)
        }
        </div>
    )
}