import { useEffect, useState } from "react";
import styles from "../../../styles/Preview.module.css";
import { usePhotoStore } from "../../../function/Photos";
import NextIcon from "../../../assets/Icons/next-icon.png";

export default function PhotoPreview(props) {

    const { photos} = usePhotoStore();
    const [index, setIndex] = useState(0);
    const [hideIcon, setHideIcon] = useState("both");

    useEffect(() => {
        setIndex(photos.length - 1);
    }, [photos])
    
    useEffect(()=> {
        if(index === 0 && photos.length > 1){
            setHideIcon("prev");
        }else if(index === photos.length - 1 && photos.length > 1 ){
            setHideIcon("next");
        }else if(photos.length === 1){
            setHideIcon("both");
        }else{
            setHideIcon("");
        }

    }, [index, photos]);

    function handlePreviewIndex(event) {
        switch (event) {
            case "next":
                setIndex(index + 1)
                break;

            case "prev":
                setIndex(index - 1)
                break;
        
            default:
                break;
        }

    }

    function photoAgain(e) {
        e.preventDefault();
        props.setPhotoUrl(null);
    }

    async function photoFinish(e){
        e.preventDefault();
        localStorage.setItem("fileType", "jpeg");
        props.setView("qrcode");
    }

    return (
        <>
            <div className={`${styles.preview_container} ${props.orientation === "portrait"? styles.portrait : ""}`}>
                {photos[index] && <img className={styles.photo} src={photos[index]} alt="Photobooth result." />}

            </div>
            <div className={`${hideIcon === "both" &&(styles.hide_icon)}`}>
                <button className={`${styles.next_icon} ${hideIcon === "next" && (styles.hide_icon)}`} onClick={e => {e.preventDefault(); handlePreviewIndex("next")}}><img src={NextIcon}  alt="Next Icon"/></button>
                <button className={`${styles.prev_icon} ${hideIcon === "prev" && (styles.hide_icon)}`} onClick={e => {e.preventDefault(); handlePreviewIndex("prev")}}><img src={NextIcon} alt="prev icon" /></button>
            </div>
            <div className={styles.button_container}>
                <button className={styles.button} type="button" onClick={(e) => {photoAgain(e)}}>More</button>
                <button className={styles.button} type="button" onClick={(e) => {photoFinish(e)}}>Finish</button>
            </div>
        </>
    )
}