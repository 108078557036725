import { useState } from "react";
import styles from "../../../styles/Email.module.css";
import KeyBoard from "../../../function/Keyboard";
import { usePhotoStore } from "../../../function/Photos";
import LoaderPrimary from "../../../assets/Loader";

export default function EnterEmail(props) {

    const { photos, clearPhotos } = usePhotoStore();

    const [emailInput, setEmailInput] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const [saved, setSaved] = useState(false);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

   function saveEmail(e) {
        e.preventDefault();
        if(emailRegex.test(emailInput)){
            setLoader(true);
            photos.forEach((photo, i) => {

                const match = photo.match(/^data:(.*?);base64,/);

                const link = document.createElement('a');
  
                // Set href to the data URL and specify a filename for the download
                link.href = photo;
                link.download = `${emailInput}${i}.${match[1].split("/")[1]}`;
                
                // Append link to the document, click it, and remove it
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                if(i === photos.length - 1) {
                    setTimeout(() => {
                        clearPhotos();
                        setLoader(false);
                        setSaved(true);
                    }, 5000);
                }
            })

            //alert("Your photos are all saved. We will send it the email you provided.")
        }else{
            setError("Please enter an invalid email address.")
        }
    }

    function savingDone(e) {
        e.preventDefault();
        props.setView("");
    }

    if(loader) {
        return <LoaderPrimary />
    }

    return (
        <div className={styles.email_container}>
            {saved? <>
                <div className={styles.text_cont}>
                    <h3>Photos are saved. We will send you the download link in the email you provided.</h3>
                    <button className={styles.enter_btn} onClick={e => {savingDone(e)}} type="button">Done</button>
                </div>
            </> :
             <>
                <form className={styles.form}>
                    <div className={styles.input_container}>
                        <label className={styles.label} htmlFor="user-email">Enter Email</label>
                        <input className={styles.input} name="email" type="email" id="user-input" onChange={e => {setEmailInput(e.target.value)}} autoComplete="off"/>
                        <div className={styles.note_container}>
                            <p className={styles.note}>Note :</p>
                            <p className={styles.note_text}>Photos will be sent to the email you provided. No email will be saved to the database.</p>
                        </div>
                        {error && (<p className={styles.error_text}>{error}</p>)}
                    </div>
                    <button className={styles.enter_btn} onClick={e => {saveEmail(e)}}>Save</button>
                </form>
                <KeyBoard input={setEmailInput} form="email" />
             </>}
        </div>
        
    )
}