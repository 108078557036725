import { collection, addDoc, getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadString} from "firebase/storage";
import { app } from "../../../config/firebase";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default async function PostData(photo, fileName, permission, eventName) {
    const db = getFirestore(app);
    const storage = getStorage();
    const auth = getAuth();
  try {
    let userId = null;
    await new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          userId = user.uid;
        } else {
          window.location.href="/";
        }
        resolve(true);
      });
    });

    if (!userId) {
      return false;
    }

    const date = new Date().getTime();

    const addRes = await addDoc(collection(db, eventName), {
      event: eventName,
      picId: fileName,
      userId: userId,
      notPermitted: permission,
      timestamp: date
    });

    if (addRes) {
      const storageRef = ref(storage, addRes.id);
      const res = await uploadString(storageRef, photo, "data_url");

      if (res) {
        if(!permission){
          await addDoc(collection(db, "Slideshow","eventSlideShow", eventName), {
            picName : addRes.id,
            notPermitted: permission,
            timestamp: date
          });
        }
        
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}
  