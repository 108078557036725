import { Orientation } from "../Orientation";

export default function Resolution() {

    const orientation = Orientation();

    const settings = localStorage.getItem("settings");

    let resWidth;
    let resHeight;
    let num;
    let den;
  
    if(settings){
        const resString = settings.split(" ")[0];
        resWidth = parseInt(resString.split("x")[0]);
        resHeight = parseInt(resString.split("x")[1]);
        const aspectString = settings.split(" ")[1];
        num = parseInt(aspectString.split(":")[0]);
        den = parseInt(aspectString.split(":")[1]);
    }else{
        resHeight = 1200;
        resWidth = 1920;
        num = 10;
        den = 16;
    }
  
    if(orientation === "Landscape"){
        return {
            height: resHeight,
            width: resWidth,
            aspect: num + "/" + den
        }
    }else{
        return {
            height: resWidth,
            width: resHeight,
            aspect: den + "/" + num
        }
    }
}

