import Resolution from "../Resolution";

export default async function StartCamera(videoEl, streamRef, videoPlaying, setVideoPlaying) {
    try {
        const res = Resolution();

        if (videoEl && !videoPlaying) {
            const [num, den] = res.aspect.split("/").map(Number);

            const mediaStream = await navigator.mediaDevices.getUserMedia({
                video: {
                    width: { ideal: res.width },
                    height: { ideal: res.height }, 
                    aspectRatio: num/den,
                    facingMode: "user"
                },
                audio: false
            })

            if (!videoEl.srcObject) {
                videoEl.srcObject = mediaStream;
                streamRef = mediaStream;

                videoEl.onloadedmetadata = () => {
                    const playPromise = videoEl.play();
    
                    if (playPromise !== undefined) {
                        playPromise.then(() => {
                            setVideoPlaying(true);
                        }).catch((error) => {
                            alert("Play error:", error);
                        });
                    }
                };
            }
        }
        
    } catch (error) {
        alert("Error in opening camera:" + error)
    }
}